import { PublicClientApplication } from '@azure/msal-browser';
import store from '@/store';

const config = {
  auth: {
    tenant: process.env.VUE_APP_AZURE_AD_TENANT,
    clientId: process.env.VUE_APP_AZURE_AD_CLIENT,
    authority:
      'https://Fernapps.b2clogin.com/tfp/Fernapps.onmicrosoft.com/B2C_1_HyttaSignUpAndReset/',
    redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_AD_LOGOUT_URI,
    navigateToRequestUrl: true,
    navigateToLoginRequestUrl: false,
    knownAuthorities: ['Fernapps.b2clogin.com']
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

class Auth {
  constructor() {
    this.authContext = new PublicClientApplication(config);
  }

  bootstrap() {
    return new Promise(resolve => {
      this.authContext.handleRedirectPromise().then(async response => {
        if (response !== null) {
          await store.dispatch('auth/storeToken', response.idToken);
          await store.dispatch('users/saveUser');
          await store.dispatch('utilities/getCabinRoles');
          await store.dispatch('utilities/getContentTypes');
          await store.dispatch('utilities/getContentLanguages');
          resolve();
        } else {
          const account = this.authContext.getAllAccounts()[0];
          if (account) {
            let request = {
              account
            };
            this.authContext
              .acquireTokenSilent(request)
              .then(async response => {
                await store.dispatch('auth/storeToken', response.idToken);
                await store.dispatch('users/saveUser');
                await store.dispatch('utilities/getCabinRoles');
                await store.dispatch('utilities/getContentTypes');
                await store.dispatch('utilities/getContentLanguages');
                resolve();
              }).catch(err => {
                console.log(err);
                this.authContext.acquireTokenRedirect(request);
              });
          } else {
            resolve();
          }
        }
      });
    });
  }

  login() {
    return this.authContext.loginRedirect();
  }

  logout() {
    return this.authContext.logoutRedirect();
  }

  acquireToken() {
    return new Promise(resolve => {
      const account = this.authContext.getAllAccounts()[0];
      if (account) {
        let request = {
          account
        };
        this.authContext.acquireTokenSilent(request).then(async response => {
          await store.dispatch('auth/storeToken', response.idToken);
          resolve();
        }).catch(err => {
          console.log(err);
          this.authContext.acquireTokenRedirect(request);
        });
      } else {
        this.login();
      }
    });
  }

  isAuthenticated() {
    if (!store.getters['auth/token']) return false;

    return true;
  }
}

export default new Auth();
