<template>
  <Transition name="slide">
    <div class="full-drawer-modal" v-if="isVisible">
      <div class="window">
        <div class="window-inner">
            <slot></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
    name: 'FullDrawerModal',
    data: () => ({
      isVisible: false,
    }),
    methods: {
      open() {
        this.isVisible = true
      },
      close() {
        this.isVisible = false
      },
    },
}
</script>
<style lang="scss" scoped>
  .full-drawer-modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9999;
    overflow-y: hidden;
    @media (min-width: $breakpoint-tablet) {
      width: 350px;
    }
  }
  .window {
    position: relative;
    display: flex;
    background: #fff;
    height: 100%;
    padding: 2rem 1rem;
  }
  .slide-enter-active {
    transition: all 0.5s ease;
    transform: translateY(90%);
    opacity: 1;
  }

  .slide-enter-to {
    transition: all 0.5s ease;
    transform: translateY(0%);
  }

  .slide-leave-active {
    transition: all 0.5s ease;
    transform: translateY(30%);
    opacity: 0;
  }
</style>
