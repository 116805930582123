<template>
  <footer class="main-footer" :class="{ 'noFooterMobile': $route.meta.showCabinMenu }">
    <div class="main-footer-sub">
      <row class="main-footer_content" container :gutter="12">
        <column :xs="12" :md="3" :lg="3">
          <img alt="Hytta" width="100" src="../assets/hytta_green.png" />
        </column>
        <column :xs="12" :md="1" :lg="1"></column>
        <column :xs="12" :md="3" :lg="3"></column>
        <column :xs="12" :md="1" :lg="1"></column>
        <column :xs="12" :md="3" :lg="3">
          <ul class="footer-menu">
            <li v-for="item in menuOne" :key="item.name">
              <router-link :to="{ name: item.routeName }"><fa icon="arrow-right" /> {{item.name}}</router-link>
            </li>
          </ul>
        </column>
      </row>
      <row class="main-footer_creds" container :gutter="12">
        <column :xs="12" :md="4" :lg="3">&copy; Fernapps AS {{ getYear() }}</column>
      </row>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      menuOne: [
        {
          name: 'Om Hytta',
          routeName: 'About'
        },
        {
          name: 'Hjelp',
          routeName: 'Help'
        },
        {
          name: 'Personvern',
          routeName: 'Privacy'
        },
        {
          name: 'Partnere & Utviklere',
          routeName: 'Partners'
        },
        {
          name: 'Kontakt',
          routeName: 'Contact'
        }
      ],
      showCabinMenu: false
    };
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-footer {
  flex-shrink: 0;
  background-color: #1C2D31;
  color: $spring;
  @include gutter-padding;
  &_content {
    padding: 3rem 0;
    .footer-menu {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      li {
        margin-left: 0;
        svg {
          margin-right: 0.55rem;
        }
        padding: 0.75rem 0;
        font-weight: 500;
        a {
          color: white
        }
      }
    }
    p {
      margin-top: 1.5rem;
      font-size: 1.2rem;
    }
    a {
      color: white;
      &:hover {
        color: $light-grey;
      }
    }
  }
  &_creds {
    padding-bottom: 2rem;
    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 2rem;
    }
    font-weight: 500;
    color: $hytta;
  }
}
.main-footer.noFooterMobile {
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}
</style>
