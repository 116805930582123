<template>
  <Menu :activeMenu="activeMenu" @close-menu="closeMenu($event)"></Menu>
  <Header :activeMenu="activeMenu" @menu-toggle="menuToggler"></Header>
  <main class="main-content">
    <router-view :key="$route.fullPath"></router-view>
  </main>
  <Footer></Footer>
</template>

<script>
import Menu from '@/components/Menu';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  components: {
    Menu,
    Header,
    Footer
  },
  data() {
    return {
      activeMenu: false
    }
  },
  methods: {
    menuToggler() {
      this.activeMenu = !this.activeMenu;
    },
    closeMenu(event) {
      if(!event.target.closest('#menu-toggle')) {
        this.activeMenu = false;
      }
    }
  },
  watch: {
    $route () {
      this.activeMenu = false;
    }
  }
}
</script>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .main-content {
    flex: 1 0 auto;
  }
  .main-footer {
    flex-shrink: 0;
    padding: 20px;
  }
  .grid-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
</style>
