<template>
  <li class="notification">
    <p>{{ notification.cabinUserFirstName }} ønsker å booke {{ cabin.name }} 
      fra {{ formatDate(notification.fromDateUtc) }} til {{ formatDate(notification.toDateUtc) }} 
    </p>
    <div class="notification-actions">
      <Button @click="acceptBooking()" id="acceptBookingButton" symbol="check" color="green"></Button>
      <Button @click="denyBooking()" id="denyBookingButton" symbol="times" color="red"></Button>
    </div>
  </li>
</template>
<script>
import Helpers from '@/helpers/helpers';
import BookingService from '@/services/BookingService';
import CabinService from '@/services/CabinService';
import Button from '@/components/Button';

export default {
  name: 'UserBookingNotification',
  components: {
    Button
  },
  props: {
    notification: Object
  },
  data() {
    return {
      cabin: {}
    }
  },
  methods: {
    formatDate: Helpers.formatDate,
    acceptBooking() {
      BookingService.acceptBooking(this.notification.id, true).then(async res => {
        await this.$store.dispatch('users/getBookingsAwaitingApproval');
        console.log(res.data)
        this.$forceUpdate();
        this.$router.push({ name: 'hytte', params: { cabinId: res.data.cabinId } });
      }).catch(err => {
        console.log(err);
        this.$toast.error('Kunne ikke akseptere booking')
      })
    },
    denyBooking() {
      BookingService.deleteBooking(this.notification.id, true).then(async res => {
        console.log(res);
        await this.$store.dispatch('users/getBookingsAwaitingApproval');
      }).catch(err => {
        console.log(err);
        this.$toast.error('Kunne ikke avslå booking')
      })
    },
  
  },
  beforeMount() {
    CabinService.getCabin(this.notification.cabinId).then(res => {
      this.cabin = res.data;
    })
  }
}
</script>
<style lang="scss" scoped>
  .notification {
    background-color: #F2F2F2;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 1rem;
    .notification-actions {
      display: flex;
      justify-content: space-between;
      button {
        width: 100%;
      }
      button:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
</style>
