import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { SetupCalendar, Calendar } from 'v-calendar';
import { Row, Column, Hidden } from 'vue-grid-responsive';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'v-calendar/dist/style.css';
import {
  faArrowRight,
  faArrowLeft,
  faBars,
  faPlus,
  faTimes,
  faMinus,
  faTrash,
  faBell,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Auth from './helpers/auth';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import Toaster from '@meforma/vue-toaster';

import './registerServiceWorker'

library.add(faArrowRight);
library.add(faArrowLeft);
library.add(faBars);
library.add(faPlus);
library.add(faTimes);
library.add(faMinus);
library.add(faTrash);
library.add(faBell);
library.add(faCheck);

Auth.bootstrap().then(() => {
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(Toaster, {
      position: 'bottom',
      dismissable: true
    })
    .use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_MAPS_KEY,
        libraries: 'places'
      }
    })
    .use(SetupCalendar, {});

  /** Register Global components here */
  app.component('row', Row);
  app.component('column', Column);
  app.component('hidden', Hidden);
  app.component('fa', FontAwesomeIcon);
  app.component('Calendar', Calendar);
  app.config.globalProperties.$auth = Auth;

  app.mount('#app');
});
