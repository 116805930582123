import { createStore } from 'vuex'
import auth from './modules/auth'
import users from './modules/users'
import utilities from './modules/utilities'
// import VuexPersistence from 'vuex-persist'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// });

export default createStore({
  modules: {
    auth,
    users,
    utilities
  },
  // plugins: [vuexLocal.plugin]
})
