<template>
  <div v-click-outside="onClickOutside" :class="{active: activeMenu}" class="main-menu">
    <div class="main-menu_menus">
      <ul>
        <li v-for="item in menuLinksFirstFiltered" :key="item.name">
          <router-link :to="{ name: item.routeName }">{{item.name}}</router-link>
        </li>
      </ul>
      <ul>
        <li v-for="item in menuLinksSecondFiltered" :key="item.name">
          <router-link :to="{ name: item.routeName }">{{item.name}}</router-link>
        </li>
      </ul>
      <ul v-if="isAuth && !isPlayStore">
        <li><a href="#" @click.stop="SignOut()">Logg ut</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'Menu',
  props: {
    activeMenu: Boolean
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      menuLinksFirst: [
        {
          name: 'Hjem',
          routeName: 'dashboard',
          requiresAuth: true
        },
        {
          name: 'Hytter',
          routeName: 'Cabins',
          requiresAuth: true
        }
      ],
      menuLinksSecond: [
        {
          name: 'Hjelp',
          routeName: 'Help',
          requiresAuth: false
        },
        {
          name: 'Om Hytta',
          routeName: 'About',
          requiresAuth: false
        },
        {
          name: 'Partnere',
          routeName: 'Partners',
          requiresAuth: false
        },
        {
          name: 'Kontakt',
          routeName: 'Contact',
          requiresAuth: false
        }
      ]
    };
  },
  computed: {
    menuLinksFirstFiltered() {
      return this.menuLinksFirst.filter(item => {
        if (item.requiresAuth) {
          return this.$auth.isAuthenticated() ? true : false;
        } else {
          return true;
        }
      });
    },
    menuLinksSecondFiltered() {
      return this.menuLinksSecond.filter(item => {
        if (item.requiresAuth) {
          return this.$auth.isAuthenticated() ? true : false;
        } else {
          return true;
        }
      });
    },
    isAuth() {
      return this.$auth.isAuthenticated();
    },
    isPlayStore() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('source') === 'playstore') {
          return true;
      }

      return false
    }
  },
  methods: {
    async SignOut() {
      await this.$auth
        .logout()
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        });
    },
    onClickOutside (event) {
      this.$emit('close-menu', event);
    }
  }
};
</script>

<style lang="scss" scoped>
.main-menu {
  position: fixed;
  right: -2000px;
  width: 100%;
  @media (min-width: $breakpoint-mobile) {
    width: 35%;
  }
  height: 100%;
  background-color: $hytta;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  &_menus {
    margin-right: 2rem;
    text-align: right;
    ul {
      list-style-type: none;
      margin: 0;

    }
    li {
      padding: 0.75rem 0;
    }
    li a {
      font-size: 40px;
      text-decoration: none;
      color: white;
      line-height: 48px;
    }
  }
}
.active {
  right: 0;
}
.divider {
  color: white;
  font-size: 40px;
}
</style>
