import axios from 'axios';
import store from '@/store';
import Auth from '../helpers/auth';

const http = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-type': 'application/json'
  }
});

http.interceptors.request.use(
  async config => {
    if (!store.getters['auth/token']) {
      await Auth.acquireToken();
    }
    config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(response => {
  return response;
}, async error => {
  if (error.response.status === 401) {
    await Auth.acquireToken();
  }
  return error;
});

export default http;
