<template>
  <button :id="id" class="button" :class="[color, size]">
    <fa v-if="symbol" :icon="symbol" />
    {{ text }}
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    text: String,
    color: String,
    size: String,
    symbol: String,
    id: String,
  }
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  padding: 1rem 1rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.button.large {
  font-size: 18px;
  padding: 1.5rem 1rem;
}
.button.small {
  font-size: 14px;
  padding: .75rem 1rem;
}
.button.full {
  font-size: 18px;
  padding: 1rem 1rem;
  width: 100%;
}
.button.green {
  background-color: $hytta;
  &:hover {
    background-color: darken($hytta, 5%)
  }
}
.button.lightgreen {
  background: rgba(#4F6D5E, 0.2);
  color: black;
  &:hover {
    background-color: darken(rgba(#4F6D5E, 0.2), 5%)
  }
}
.button.darkgreen {
  background: rgba($hytta, 0.2);
  color: black;
  &:hover {
    background-color: darken(rgba($hytta, 0.2), 5%)
  }
}
.button.white {
  background-color: white;
  color: $mountain;
  &:hover {
    background-color: darken(white, 5%)
  }
}
.button.red {
  background-color: $red;
  &:hover {
    background-color: darken($red, 5%)
  }
}
.button.grey {
  background-color: #E2E7E5;
  color: black;
  &:hover {
    background-color: darken(#E2E7E5, 5%)
  }
}
.button.darkgrey {
  background-color: rgba(#1F3333BF, 0.75);
  color: black;
  &:hover {
    background-color: darken(#E2E7E5, 5%)
  }
}
.button.pink {
  background-color: $pink;
  color: black;
  &:hover {
    background-color: darken($pink, 5%)
  }
}
.button.orange {
  background-color: $warmth;
  &:hover {
    background-color: darken($warmth, 5%)
  }
}
</style>
