import CabinService from '@/services/CabinService'
import BookingService from '@/services/BookingService';
import ChecklistService from '@/services/ChecklistService';
import ContentService from '@/services/ContentService';

const state = () => ({
  cabinRoles: [],
  bookingStatuses: [],
  checklistItemStatuses: [],
  contentTypes: [],
  contentLanguages: [],
  storageToken: ''
})

const getters = {
  cabinRoles(state) {
    return state.cabinRoles;
  },
  bookingStatuses(state) {
    return state.bookingStatuses;
  },
  checklistItemStatuses(state) {
    return state.checklistItemStatuses;
  },
  contentTypes(state) {
    return state.contentTypes;
  },
  contentLanguages(state) {
    return state.contentLanguages;
  },
  storageToken(state) {
    return state.storageToken;
  } 
};

const actions = {
  getCabinRoles({ commit }) {
    return new Promise((resolve, reject) => {
      CabinService.getCabinRoles().then((res) => {
        commit('saveCabinRoles', res.data);
        resolve();
      }).catch((error) =>{
        console.log(error)
        reject()
      });
    })
  },
  getBookingStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      BookingService.getBookingStatuses().then((res) => {
        commit('saveBookingStatuses', res.data);
        resolve();
      }).catch((error) =>{
        console.log(error)
        reject()
      });
    })
  },
  getChecklistStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      ChecklistService.getChecklistItemStatuses().then((res) => {
        commit('saveChecklistItemStatuses', res.data);
        resolve();
      }).catch((error) =>{
        console.log(error)
        reject()
      });
    })
  },
  getContentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      ContentService.getContentTypes().then((res) => {
        commit('saveContentTypes', res.data);
        resolve();
      }).catch((error) =>{
        console.log(error)
        reject()
      });
    })
  },
  getContentLanguages({ commit }) {
    return new Promise((resolve, reject) => {
      ContentService.getContentLanguages().then((res) => {
        commit('saveContentLanguages', res.data);
        resolve();
      }).catch((error) =>{
        console.log(error)
        reject()
      });
    })
  }
};

const mutations = {
  saveCabinRoles(state, payload) {
    state.cabinRoles = payload;
  },
  saveChecklistItemStatuses(state, payload) {
    state.checklistItemStatuses = payload;
  },
  saveBookingStatuses(state, payload) {
    state.bookingStatuses = payload;
  },
  saveContentTypes(state, payload) {
    state.contentTypes = payload;
  },
  saveContentLanguages(state, payload) {
    state.contentLanguages = payload;
  },
  setStorageToken(state, payload) {
    state.storageToken = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
