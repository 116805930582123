<template>
  <li class="notification">
    <p>Du har blitt invitert til hytte {{ notification.cabinName }}</p>
    <div class="notification-actions">
      <Button @click="acceptInvitation()" id="acceptInvitationButton" symbol="check" color="green"></Button>
      <Button @click="denyInvitation()" id="denyInvitationButton" symbol="times" color="red"></Button>
    </div>
  </li>
</template>
<script>
import CabinService from '@/services/CabinService';
import Button from '@/components/Button';

export default {
  name: 'UserInviteNotification',
  components: {
    Button
  },
  props: {
    notification: Object
  },
  methods: {
    acceptInvitation() {
      CabinService.acceptCabinUserInvite(this.notification.id).then(async res => {
        console.log(res);
        await this.$store.dispatch('users/getInvitations');
        this.$forceUpdate();
        this.$router.push({ name: 'hytte', params: { cabinId: this.notification.cabinId } });
      }).catch(err => {
        console.log(err);
        this.$toast.error(`Kunne ikke akspetere hytte invitasjonen`);
      })
    },
    denyInvitation() {
      CabinService.deleteCabinUserInvite(this.notification.id).then(async res => {
        console.log(res);
        await this.$store.dispatch('users/getInvitations');
      }).catch(err => {
        console.log(err);
        this.$toast.error(`Kunne ikke slette hytte invitasjonen`);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .notification {
    background-color: #F2F2F2;
    margin-bottom: 1rem;
        border-radius: 8px;
    padding: 1rem;
    .notification-actions {
      display: flex;
      justify-content: space-between;
      button {
        width: 100%;
      }
      button:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
</style>
