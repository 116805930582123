import http from '../helpers/http-common';

class ChecklistService {
  getChecklist(id) {
    return http.get(`/Checklist/GetChecklistAsync/${id}`);
  }

  getChecklists(cabinId) {
    return http.post('/Checklist/GetChecklistsAsync', {
      cabinId
    });
  }

  addChecklist(cabinId, name, content, checklistItems) {
    return http.post('/Checklist/AddChecklistAsync', {
      cabinId,
      name,
      content,
      checklistItems
    });
  }

  updateChecklist(id, name, content, checklistItems) {
    return http.put('/Checklist/UpdateChecklistAsync', {
      id,
      name,
      content,
      checklistItems
    });
  }

  deleteChecklist(checklistId) {
    return http.delete('/Checklist/DeleteChecklistAsync', {
      data: {
        checklistId
      }
    });
  }
  addChecklistItemContent(checklistItemId, contentLanguageId, contentTypeId, text, contentAsBase64, contentName, defaultStatus, sequence) {
    return http.post('/Checklist/AddChecklistItemContentAsync', {
      checklistItemId,
      contentLanguageId,
      contentTypeId,
      text,
      contentAsBase64,
      contentName,
      default: defaultStatus,
      sequence
    });
  }
  deleteChecklistItemContent(checklistItemContentId) {
    return http.delete('/Checklist/DeleteChecklistItemContentAsync', {
      data: {
        checklistItemContentId
      }
    })
  }
  getChecklistHistory(checklistHistoryId) {
    return http.get(
      `/Checklist/GetChecklistHistoryAsync/${checklistHistoryId}`
    );
  }

  addChecklistHistory(checklistId, content, checklistItems) {
    return http.post('/Checklist/AddChecklistHistoryAsync', {
      checklistId,
      content,
      checklistItems
    });
  }

  updateChecklistItemHistory(
    checklistHistoryId,
    userId,
    checklistHistoryItems
  ) {
    return http.put('/Checklist/UpdateChecklistItemHistoryStatusAsync', {
      checklistHistoryId,
      userId,
      checklistHistoryItems
    });
  }

  deleteChecklistHistory(checklistHistoryId) {
    return http.delete('/Checklist/DeleteChecklistHistoryAsync', {
      checklistHistoryId
    });
  }

  getChecklistItemStatuses() {
    return http.get('/Checklist/GetChecklistItemStatusesAsync');
  }
}

export default new ChecklistService();
