<template>
  <full-drawer-modal ref="popup">
    <div class="cancel-button">
      <Close @click="hide()" />
    </div>
    <h2 style="margin-top: 0">{{ title }}</h2>
    <div id="notifications-center">
      <ul class="notifications">
        <UserInviteNotification
          v-for="(item, index) in cabinNotifications"
          :key="index"
          :notification="item"
        >
        </UserInviteNotification>
        <UserBookingNotification
          v-for="(item, index) in userNotifications"
          :key="index"
          :notification="item"
        >
        </UserBookingNotification>
      </ul>
    </div>
  </full-drawer-modal>
</template>

<script>
import FullDrawerModal from './FullDrawerModal'
import UserInviteNotification from '@/components/notifications/UserInviteNotification';
import UserBookingNotification from '@/components/notifications/UserBookingNotification';
import Close from '@/assets/close.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationsDialog',
  components: {
    FullDrawerModal,
    UserInviteNotification,
    UserBookingNotification,
    Close
  },
  data() {
    return {
      title: 'Varslinger'
    }
  },
  computed: {
    ...mapGetters({
      'cabinNotifications': 'users/cabinNotifications',
      'userNotifications': 'users/bookingNotifications'
    }),
  },
  methods: {
    show() {
      this.$refs.popup.open()

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hide() {
      this.$refs.popup.close()
    },
  },
  mounted() {
    console.log('NotificationsDialog');
  }
}
</script>
<style lang="scss" scoped>
  .cancel-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
  }
  #notifications-center {
    background-color: white;
    border-radius: 8px;
    margin-top: 3rem;
    .notifications {
      margin: 0!important;
      padding: 0!important;
      list-style-type: none;
    }
  }
</style>