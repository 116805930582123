import http from '../helpers/http-common';

class ContentService {
  getContentTypes() {
    return http.get('/Content/GetContentTypesAsync');
  }

  getContentLanguages() {
    return http.get('/Content/GetContentLanguagesAsync');
  }
}

export default new ContentService();
