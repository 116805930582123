import http from '../helpers/http-common';

class CabinService {
  getCabin(id) {
    return http.get(`/Cabin/GetCabinAsync/${id}`);
  }

  getCabins(userId) {
    return http.post('/Cabin/GetCabinsAsync', {
      userId
    });
  }

  addCabin(name, ownerId, address, postalCode, longitude, latitude, content) {
    return http.post('/Cabin/AddCabinAsync', {
      name,
      ownerId,
      address,
      postalCode,
      longitude,
      latitude,
      content
    });
  }

  updateCabin(
    cabinId,
    name,
    address,
    postalCode,
    longitude,
    latitude,
    content,
    cabinUserRoles
  ) {
    return http.put('/Cabin/UpdateCabinAsync', {
      cabinId,
      name,
      address,
      postalCode,
      longitude,
      latitude,
      content,
      cabinUserRoles
    });
  }

  deleteCabin(cabinId) {
    return http.delete('/Cabin/DeleteCabinAsync', {
      data: {
        cabinId
      }
    });
  }

  getCabinUserRoles(cabinId) {
    return http.get(`/Cabin/GetCabinsUserRolesAsync/${cabinId}`);
  }
  updateCabinUserRole(id, cabinRoleId) {
    return http.put(`/Cabin/UpdateCabinUserRoleAsync`, {
      id,
      cabinRoleId
    })
  }
  deleteCabinUserRole(id) {
    return http.delete('/Cabin/DeleteCabinUserRoleAsync', {
      data: {
        id
      }
    });
  }

  addCabinUserInvite(name, email, cabinId, cabinRoleId) {
    return http.post('/Cabin/AddCabinUserInviteAsync', {
      name,
      email,
      cabinId,
      cabinRoleId,
      sendNotification: true
    })
  }

  getCabinRoles() {
    return http.get('/Cabin/GetCabinRolesAsync');
  }

  getUsersCabinUserInvites() {
    return http.get('/Cabin/GetUsersCabinUserInvitesAsync');
  }

  acceptCabinUserInvite(id) {
    return http.post('/Cabin/AcceptCabinUserInviteAsync', {
      id
    })
  }

  deleteCabinUserInvite(id) {
    return http.delete('/Cabin/DeleteCabinUserInviteAsync', {
      data: {
        id
      }
    })
  }

  addCabinContent(cabinId, contentLanguageId, contentTypeId, text, contentAsBase64, contentName, isDefault, sequence) {
    return http.post('/Cabin/AddCabinContentAsync', {
      cabinId,
      contentLanguageId,
      contentTypeId,
      text,
      contentAsBase64,
      contentName,
      default: isDefault,
      sequence
    });
  }

  updateCabinContent(id, contentLanguageId, contentTypeId, text, contentAsBase64, contentName, isDefault, sequence) {
    return http.post('/Cabin/UpdateCabinContentAsync', {
      id,
      contentLanguageId,
      contentTypeId,
      text,
      contentAsBase64,
      contentName,
      default: isDefault,
      sequence
    });
  }

  deleteCabinContent(cabinContentId) {
    return http.delete('/Cabin/DeleteCabinContentAsync', {
      data: {
        cabinContentId
      }
    })
  }
}

export default new CabinService();
