import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../helpers/auth'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/om-hytta',
    name: 'About',
    meta: {
      headerDark: true,
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/hjelp',
    name: 'Help',
    meta: {
      headerDark: true,
    },
    component: () => import('../views/Help.vue')
  },
  {
    path: '/personvern',
    name: 'Privacy',
    meta: {
      headerDark: true,
    },
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/partnere',
    name: 'Partners',
    meta: {
      headerDark: true,
    },
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/kontakt',
    name: 'Contact',
    meta: {
      headerDark: true,
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      headerDark: true,
      requiresAuthentication: true
    },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/hytter',
    name: 'Cabins',
    meta: {
      headerDark: true,
      requiresAuthentication: true
    },
    component: () => import('../views/cabins/index.vue')
  },
  {
    path: '/hytter/opprett',
    name: 'AddCabin',
    meta: {
      headerDark: true,
      requiresAuthentication: true
    },
    component: () => import('../views/cabins/AddCabin.vue')
  },
  {
    path: '/hytte/:cabinId',
    name: 'hytte',
    redirect: { name: 'calendar' },
    meta: {
      showCabinMenu: true,
      headerDark: true,
      requiresAuthentication: true
    },
    component: () => import('../views/cabin/index.vue'),
    children: [
      {
        name: 'calendar',
        path: 'kalender',
        component: () => import('../views/cabin/Calendar.vue')
      },
      {
        name: 'checklists',
        path: 'sjekklister',
        meta: {
          menuName: 'Sjekklister'
        },
        component: () => import('../views/cabin/checklists/NewChecklists.vue'),
      },
      {
        name: 'editChecklists',
        path: 'sjekklister/rediger',
        meta: {
          menuName: 'Rediger sjekklister'
        },
        component: () => import('../views/cabin/checklists/EditChecklists.vue')
      },
      {
        name: 'addChecklist',
        path: 'sjekklister/ny',
        meta: {
          menuName: 'Ny sjekkliste'
        },
        component: () => import('../views/cabin/checklists/AddChecklist.vue')
      },
      {
        name: 'checklist',
        path: 'sjekkliste/:checklistId',
        component: () => import('../views/cabin/checklists/Checklist.vue'),
        meta: {
          menuName: 'Sjekkliste'
        }
      },
      {
        name: 'editChecklist',
        path: 'sjekkliste/:checklistId/edit',
        component: () => import('../views/cabin/checklists/EditChecklist.vue'),
        meta: {
          menuName: 'Sjekkliste'
        }
      },
      {
        name: 'cabinfeed',
        path: 'hyttebok',
        meta: {
          menuName: 'Hytteboka'
        },
        component: () => import('../views/cabin/CabinFeed.vue')
      },
      {
        name: 'settings',
        path: 'innstillinger',
        meta: {
          menuName: 'Innstillinger'
        },
        component: () => import('../views/cabin/Settings.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

// Global route guard
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    if (Auth.isAuthenticated()) {
      await store.dispatch('users/getInvitations');
      await store.dispatch('users/getBookingsAwaitingApproval');
      next();
    } else {
      Auth.login();
    }
  } else {
    next();
  }
});

export default router
