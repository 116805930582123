<template>
  <header class="main-header">
    <row container :gutter="12">
      <column class="main-header_inner" :xs="12" :md="12" :lg="12">
        <div v-if="!$route.meta.menuName" class="logo">
          <router-link :to="isAuth ? { name: 'dashboard' } : { name: 'Home' }">
            <img v-if="headerDark" alt="Hytta" width="100" src="../assets/hytta_dark.png" />
            <img v-else alt="Hytta" width="100" src="../assets/hytta_white.png" />
          </router-link>
        </div>
        <div v-else class="title">
          {{ $route.meta.menuName }}
        </div>
        <div class="menu">
          <router-link
            v-if="filteredMenu.length"
            class="cabin-menu-item"
            :to="{ name: 'dashboard' }">
            <Dash />
            Oversikt
          </router-link>
          <router-link
            class="cabin-menu-item dashboard-link"
            v-for="item in filteredMenu"
            :to="{ name: item.path }"
            :key="item.name">
              <Home v-if="item.icon === 'home'" />
              <Hammer v-if="item.icon === 'hammer'" />
              <Scroll v-if="item.icon === 'scroll'" />
              <Book v-if="item.icon === 'book'" />
              {{ item.name }}
          </router-link>
        </div>
        <div class="actions" :class="{fixedActions: activeMenu}">
          <Button
            v-if="isAuth && !activeMenu"
            @click="toggleNotifications()"
            symbol="bell"
            size="small"
            color="white"
          ><span v-if="numberOfNotifications > 0" id="notifications-counter">{{ numberOfNotifications }}</span></Button>
          <NotificationsDialog v-if="numberOfNotifications" ref="notifications"></NotificationsDialog>
          <Button v-if="!activeMenu" @click="openMenu" id="menu-toggle" symbol="bars" size="small" color="white"></Button>
          <Button class="menu-toggler" v-else @click="closeMenu" id="menu-toggle" size="small" symbol="times" color="white"></Button>
        </div>
      </column>
    </row>
  </header>
</template>

<script>
import Button from '@/components/Button';
import Home from '@/assets/home.svg';
import Hammer from '@/assets/hammer.svg';
import Scroll from '@/assets/scroll.svg';
import Dash from '@/assets/hyttaDash.svg';
import Book from '@/assets/bookClosed.svg';
import NotificationsDialog from '@/components/modals/NotificationsDialog';
import { mapGetters } from 'vuex';


export default {
  name: 'Header',
  props: {
    activeMenu: Boolean
  },
  components: {
    Button,
    NotificationsDialog,
    Home,
    Hammer,
    Scroll,
    Dash,
    Book
  },
  data() {
    return {
      showCabinMenu: false,
      headerDark: false,
      showNotifications: false,
      cabinMenu: [
        {
          key: 1,
          name: 'Kalender',
          path: 'calendar',
          icon: 'home'
        },
        {
          key: 2,
          name: 'Sjekklister',
          path: 'checklists',
          icon: 'scroll'
        },
        {
          key: 3,
          name: 'Hytteboka',
          path: 'cabinfeed',
          icon: 'book'
        },
        {
          key: 4,
          name: 'Innstillinger',
          path: 'settings',
          icon: 'hammer'
        }
      ]
    };
  },
  watch: {
    $route(to) {
      to.meta.showCabinMenu
        ? (this.showCabinMenu = true)
        : (this.showCabinMenu = false);
      to.meta.headerDark ? (this.headerDark = true) : (this.headerDark = false);
    }
  },
  computed: {
    ...mapGetters([
      'users/cabinNotifications',
      'users/bookingNotifications'
    ]),
    filteredMenu() {
      return this.showCabinMenu && !this.activeMenu ? this.cabinMenu : [];
    },
    isAuth() {
      return this.$auth.isAuthenticated();
    },
    numberOfNotifications() {
      return this['users/cabinNotifications'].length + this['users/bookingNotifications'].length;
    },
    cabinNotifications() {
      return this['users/cabinNotifications'] || [];
    },
    bookingNotifications() {
      return this['users/bookingNotifications'] || [];
    }
  },
  methods: {
    openMenu() {
      this.$emit('menu-toggle', true);
      this.activeNotifications = false;
    },
    closeMenu() {
      this.$emit('menu-toggle', false);
    },
    toggleNotifications() {
      if(this.numberOfNotifications) {
        this.$refs.notifications.show();
      }
    },
    async SignIn() {
      await this.$auth
        .login()
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(`Error during authentication: ${error}`);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-header {
  background-color: transparent;
  @include gutter-padding;
  z-index: 99999;
  &_inner {
    display: flex;
    justify-content: space-between;
    .logo {
      display: flex;
      align-items: center;
      z-index: 1;
      img {
        margin-top: .1rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
    }
    .actions, .menu {
      display: flex;
      align-items: center;
      a.dashboard-link {
        svg {
          fill: white;
          stroke: none;
          path {
            fill: white;
            stroke: none;
          }
        }
      }
      a.cabin-menu-item {
        background-color: rgba(0, 0, 0, 0.61);
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        border-radius: 8px;
        padding: 0.75rem 1rem;
        color: white;
        text-decoration: none;
        cursor: pointer;
        display: none;
        svg {
          margin-right: .5rem;
          fill: white!important;
          path {
            fill: white!important;
          }
        }
        @media (min-width: $breakpoint-mobile) {
          display: flex;
        }
      }
      a.cabin-menu-item.router-link-active {
        color: white;
        background-color: $hytta;
      }

      #notifications-counter {
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        background-color: $blue;
        padding: .1rem .4rem;
        color: white;
      }
      #notifications-center {
        background-color: white;
        position: absolute;
        top: 60px;
        width: 250px;
        right: 58px;
        border-radius: 8px;
        padding: 1rem;
        .notifications {
          margin: 0!important;
          padding: 0!important;
          list-style-type: none;
        }
      }
      button:not(:last-child),
      a:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .actions.fixedActions {
      position: fixed;
      right: 25px;
      @media (min-width: $breakpoint-mobile) {
        right: 50px;
      }
    }
  }
}
</style>
