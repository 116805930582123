class Helpers {
  // Formats the dates in the app to norwegian format
  formatDate(utcDate) {
    if(!utcDate) return;
    let date = new Date(utcDate);
    return date.toLocaleString('nb-NO', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }

  // Append image size to the url for any image in the app
  createUrlForCustomSize(imageUrl, size) {
    if(!imageUrl) return false;

    let selectedSize;
    switch(size) {
      case 'small':
        selectedSize = '_160x120';
        break;
      case 'medium':
        selectedSize = '_320x240';
        break;
      case 'large':
        selectedSize = '_800x600';
        break;
      default:
        selectedSize = null;
    }

    if(selectedSize === null) {
      return imageUrl;
    }

    return `${imageUrl.substring(0, imageUrl.lastIndexOf('.'))}${selectedSize}${imageUrl.substring(imageUrl.lastIndexOf('.'))}`;
  }

  isMobile() {
    if (screen.width <= 760) {
      return true
    } else {
      return false
    }
  }


  convertDateIgnoresTimezone(date) {
    if(!date) return;
    let d = new Date(date);
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000);
  }

  fromUTC(datetime) {
    const myDate = (typeof datetime === 'number')
      ? new Date(datetime)
      : datetime;
  
    if (!myDate || (typeof myDate.getTime !== 'function')) {
      return 0;
    }
  
    const getUTC = myDate.getTime();
    const offset = 60 * 60000;

    return getUTC + offset;
  }
  
  determineCabinUserRole(cabin, userId) {
    let userRole = cabin.cabinUserRoles.find(item => {
      return item.cabinUserId === userId;
    });

    if(!userRole) {
      return false;
    }

    return userRole.cabinRoleId;
  }
}

export default new Helpers();
