import http from '../helpers/http-common';

class BookingService {
  getBooking(id) {
    return http.get(`/Booking/GetBookingAsync/${id}`);
  }
  getBookings(cabinId, userId) {
    return http.post('/Booking/GetBookingsAsync', {
      cabinId,
      userId
    });
  }

  getActiveBookings() {
    return http.get(`/Booking/GetActiveBookingsAsync`);
  }

  addBooking(
    cabinId,
    fromDateUtc,
    toDateUtc,
    numberOfPeople,
    bookingStatusId,
    content,
    sendNotification
  ) {
    return http.post('/Booking/AddBookingAsync', {
      cabinId,
      fromDateUtc,
      toDateUtc,
      numberOfPeople,
      bookingStatusId,
      content,
      sendNotification
    });
  }

  updateBooking(
    bookingId,
    fromDateUtc,
    toDateUtc,
    numberOfPeople,
    bookingStatusId,
    content
  ) {
    return http.put('/Booking/UpdateBookingAsync', {
      bookingId,
      fromDateUtc,
      toDateUtc,
      numberOfPeople,
      bookingStatusId,
      content
    });
  }

  acceptBooking(id, sendNotification) {
    return http.post('/Booking/AcceptBookingAsync', {
      id,
      sendNotification
    })
  }

  deleteBooking(bookingId, sendNotification) {
    return http.delete('/Booking/DeleteBookingAsync', {
      data: {
        bookingId,
        sendNotification
      }
    });
  }

  getBookingsAwaitingApproval() {
    return http.get('/Booking/GetBookingsAwaitingApprovalAsync')
  }

  getBookingStatuses() {
    return http.get('/Booking/GetBookingStatusesAsync');
  }
}

export default new BookingService();
