import http from '../helpers/http-common';

class UserService {
  getUser() {
    return http.get('/User/GetUserInfo');
  }

  getUserInfoFromB2CAsync(ids) {
    return http.post('/User/GetUserInfoFromB2CAsync', ids);
  }
}

export default new UserService();
